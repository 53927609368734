import { faBuilding, faSitemap, faStoreAlt } from '@fortawesome/pro-light-svg-icons';
import {
  faComments,
  faFlag,
  faHome,
  faLanguage,
  faPollH,
  faUser,
  faUsersCog
} from '@fortawesome/pro-regular-svg-icons';

// eslint-disable-next-line no-underscore-dangle
const getEnv = key => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

export const MAX_FILE_SIZE = 10; // in Mo
export const FRENCH_LOCALE = 'fr_FR';

export const setCurrentVersion = (version = 1) => `v${version}`;
export const formatVersion = (versionString) => {
  if (versionString.includes('-frontend.') || versionString.includes('.')) {
    const versionMatch = versionString.match(/(\d+\.\d+\.\d+)/);
    if (versionMatch && versionMatch[1]) {
      return `v${versionMatch[1]}`;
    }
  }
  return versionString.startsWith('v') ? versionString : `v${versionString}`;
};
export const ROOT_PATH = getEnv('ROOT_PATH');
export const API_V2_URL = getEnv('API_V2_URL');
export const DATABILAN_URL = getEnv('DATABILAN_URL');
export const DATAC2E_URL = getEnv('DATAC2E_URL');
export const DATAFORM_URL = getEnv('DATAFORM_URL');
export const DATAFLUIDES_URL = getEnv('DATAFLUIDES_URL');
export const AFF_URL = 'https://aff-asso.com';
export const DATABILAN_API_URL = `${DATABILAN_URL}api`;
export const DATAC2E_API_URL = `${DATAC2E_URL}api`;
export const DATAFORM_API_URL = `${DATAFORM_URL}api`;
export const DATAFLUIDES_API_URL = `${DATAFLUIDES_URL}api`;
export const MAPBOX_TOKEN = getEnv('MAPBOX_TOKEN');

export const APP_PROFILE = getEnv('PROFILE');

export const KEYCLOAK_REALM = getEnv('KEYCLOAK_REALM');
export const KEYCLOAK_AUTH_SERVER_URL = getEnv('KEYCLOAK_AUTH_SERVER_URL');
export const KEYCLOAK_RESOURCE = getEnv('KEYCLOAK_RESOURCE');

export const RGPD_EMAIL = 'rgpd@tecnea.fr';
export const ASSISTANCE_EMAIL = 'contact@cemafroid.fr';
export const ASSISTANCE_EMAIL_AFF = 'secretariat-a.f.f@wanadoo.fr';
export const APP_VERSION = formatVersion(getEnv('VERSION'));
export const ENVIRONMENT = getEnv('ENVIRONMENT');
export const CREATED = getEnv('CREATED');
export const REVISION = getEnv('REVISION');
export const GA4_TAG_ID = getEnv('TAG_MANAGER_ID');

export const SIREN_LENGTH = 9;
export const SIRET_LENGTH = 14;
export const TVA_INTRA_LENGTH = 13;
export const LIST_SIZE = 20;

export const EDITION_PANEL_TYPES = {
  QUIZ: 'quiz',
  QUIZ_PAGES: 'quizPages'
};

export const APP_NAMES_FRONT = {
  DATATECNEA: 'DATATECNEA',
  DATABILAN: 'DATABILAN',
  DATAFLUIDES: 'DATAFLUIDES',
  DATAFORM: 'DATAFORM',
  DATAC2E: 'DATAC2E'
};

export const ROUTES = {
  ADMIN: '/administration',
  COMPANY_LIST: '/administration/societes',
  COMPANY_TREE: '/administration/societes/tree',
  COMPANY_TREE_PARAMS: '/administration/societes/tree/:companyId',
  INSTITUTION_LIST: '/administration/etablissements',
  USER_LIST: '/administration/utilisateurs',
  USER_DETAIL: '/administration/utilisateurs/:id',
  SUBSCRIPTION_LIST: '/administration/demande-acces',
  SUBSCRIPTION_DETAIL: '/administration/demande-acces/:id',
  PURCHASE_ORDER_LIST: '/administration/bons-de-commande',
  PURCHASE_ORDER_DETAIL: '/administration/bons-de-commande/:requestId',

  ADMIN_QUIZ: '/administration-quiz',
  ADMIN_QUIZ_LIST: '/administration-quiz/quiz-list',
  ADMIN_QUIZ_CREATION: '/administration-quiz/quiz',
  ADMIN_QUIZ_DETAIL: '/administration-quiz/quiz/:quizId',

  ADMIN_RESULTS: '/administration-quiz/examinations',
  ADMIN_QUIZ_RESULTS_DETAIL: '/administration-quiz/examinations/:examId',

  ADMIN_QUESTION: '/administration-quiz/questions',

  ADMIN_CATEGORIES: '/administration-quiz/categories',

  ADMIN_TRANSLATIONS: '/administration-translation',
  ADMIN_TRANSLATION_LIST: '/administration-translation/list',
  ADMIN_TRANSLATION_LIST_PARAMS: '/administration-translation/list/:application/:sourceLocale/:targetLocale',

  ADMIN_LANGUAGES: '/administration-languages',
  ADMIN_COUNTRIES: '/administration-countries',
  ADMIN_COUNTRIES_DETAIL: '/administration-countries/country',
  ADMIN_COUNTRIES_DETAIL_PARAMS: '/administration-countries/country/:countryId/:detailType',

  HOME: '/accueil',
  REGISTER: '/inscription',
  RGPD: '/mentions-legales',
  COOKIES: '/gestion-cookies',
  TEST_BUTTONS: '/test-buttons'
};

export const API_ROUTES = {
  ADMIN_QUIZ_DETAIL: quizId => `${ROUTES.ADMIN_QUIZ_CREATION}/${quizId}`,
  ADMIN_QUIZ_RESULTS_DETAIL: examId => `${ROUTES.ADMIN_RESULTS}/${examId}`,
  ADMIN_TRANSLATION_LIST_DETAIL: (application, sourceLocale, targetLocale) => (`${ROUTES.ADMIN_TRANSLATION_LIST}/${application}/${sourceLocale}/${targetLocale}`),
  ADMIN_COUNTRIES_DETAIL_COMPANY_RULES: countryId => `${ROUTES.ADMIN_COUNTRIES_DETAIL}/${countryId}/company-rules`,
  ADMIN_COUNTRIES_DETAIL_INSTITUTION_RULES: countryId => `${ROUTES.ADMIN_COUNTRIES_DETAIL}/${countryId}/institution-rules`,
  ADMIN_COUNTRIES_DETAIL_LEGAL_STATUS: countryId => `${ROUTES.ADMIN_COUNTRIES_DETAIL}/${countryId}/legal-status`,
  ADMIN_COUNTRIES_DETAIL_DOCUMENT: countryId => `${ROUTES.ADMIN_COUNTRIES_DETAIL}/${countryId}/document`,
  COMPANY_TREE_DETAILS: companyId => `${ROUTES.COMPANY_TREE}/${companyId}`,
  USER_DETAIL: personId => `${ROUTES.USER_LIST}/${personId}`
};

export const APPLICATION_ROLES = {
  ADMIN: 'ROLE_DATACOMMONS_ADMIN',
  VALIDATOR: 'ROLE_DATACOMMONS_VALIDATOR',
  DATACOMMONS_DATAFORM_VALIDATOR: 'ROLE_DATACOMMONS_DATAFORM_VALIDATOR',
  DATACOMMONS_DATABILAN_VALIDATOR: 'ROLE_DATACOMMONS_DATABILAN_VALIDATOR',
  DATACOMMONS_TRANSLATOR: 'ROLE_DATACOMMONS_TRANSLATOR',
  DATACOMMONS_TRANSLATION_VALIDATOR: 'ROLE_DATACOMMONS_TRANSLATION_VALIDATOR',
  DATABILAN_TRANSLATOR: 'ROLE_DATABILAN_TRANSLATOR',
  DATABILAN_TRANSLATION_VALIDATOR: 'ROLE_DATABILAN_TRANSLATION_VALIDATOR',
  DATAFORM_TRANSLATOR: 'ROLE_DATAFORM_TRANSLATOR',
  DATAFORM_TRANSLATION_VALIDATOR: 'ROLE_DATAFORM_TRANSLATION_VALIDATOR',
  DATAC2E_TRANSLATOR: 'ROLE_DATAC2E_TRANSLATOR',
  DATAC2E_TRANSLATION_VALIDATOR: 'ROLE_DATAC2E_TRANSLATION_VALIDATOR',
  DATAFORM_ADMIN: 'ROLE_DATAFORM_ADMIN',
  DATAFORM_CONTENT_MANAGER: 'ROLE_DATAFORM_CONTENT_MANAGER',
  DATAFLUIDES_TRANSLATOR: 'ROLE_DATAFLUIDES_TRANSLATOR',
  DATAFLUIDES_TRANSLATION_VALIDATOR: 'ROLE_DATAFLUIDES_TRANSLATION_VALIDATOR'
};

export const TRANSLATOR_ROLES = [
  APPLICATION_ROLES.DATACOMMONS_TRANSLATOR,
  APPLICATION_ROLES.DATABILAN_TRANSLATOR,
  APPLICATION_ROLES.DATAFORM_TRANSLATOR,
  APPLICATION_ROLES.DATAC2E_TRANSLATOR,
  APPLICATION_ROLES.DATAFLUIDES_TRANSLATOR
];

export const TRANSLATION_VALIDATOR_ROLES = [
  APPLICATION_ROLES.DATACOMMONS_TRANSLATION_VALIDATOR,
  APPLICATION_ROLES.DATABILAN_TRANSLATION_VALIDATOR,
  APPLICATION_ROLES.DATAFORM_TRANSLATION_VALIDATOR,
  APPLICATION_ROLES.DATAC2E_TRANSLATION_VALIDATOR,
  APPLICATION_ROLES.DATAFLUIDES_TRANSLATION_VALIDATOR
];

export const QUIZ_EDITION_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.DATAFORM_ADMIN,
  APPLICATION_ROLES.DATAFORM_CONTENT_MANAGER
];

export const VALIDATOR_ROLES = [
  APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.VALIDATOR,
  APPLICATION_ROLES.DATACOMMONS_DATABILAN_VALIDATOR,
  APPLICATION_ROLES.DATACOMMONS_DATAFORM_VALIDATOR
];

export const TRANSLATION_ROLES = [
  APPLICATION_ROLES.ADMIN,
  ...TRANSLATOR_ROLES,
  ...TRANSLATION_VALIDATOR_ROLES
];

export const MENU_ITEMS = [
  {
    title: 'menu.home',
    icon: faHome,
    url: ROUTES.HOME,
    accessRights: []
  }, {
    title: 'menu.admin',
    icon: faUsersCog,
    url: ROUTES.ADMIN,
    accessRights: VALIDATOR_ROLES
  }, {
    title: 'menu.adminQuiz',
    icon: faPollH,
    url: ROUTES.ADMIN_QUIZ,
    accessRights: QUIZ_EDITION_ROLES
  }, {
    title: 'menu.translations',
    icon: faLanguage,
    url: ROUTES.ADMIN_TRANSLATIONS,
    accessRights: TRANSLATION_ROLES
  }, {
    title: 'menu.languages',
    icon: faUser,
    secondIcon: faComments,
    url: ROUTES.ADMIN_LANGUAGES,
    accessRights: [APPLICATION_ROLES.ADMIN]
  }, {
    title: 'menu.countries',
    icon: faFlag,
    url: ROUTES.ADMIN_COUNTRIES,
    accessRights: [APPLICATION_ROLES.ADMIN]
  }
];

export const TECNEA_APPS = [
  {
    name: 'DATABILAN',
    description: 'pageHome.appDatabilan',
    link: DATABILAN_URL,
    logo: '/assets/images/favicons/databilan.svg'
  }, {
    name: 'DATAFORM',
    description: 'pageHome.appDataform',
    link: DATAFORM_URL,
    logo: '/assets/images/favicons/dataform.svg'
  }
  // {
  //   name: 'DATAC2E',
  //   description: 'pageHome.appDatac2e',
  //   link: DATAC2E_URL,
  //   logo: '/assets/images/favicons/datac2e.svg'
  // }
];

export const AFF_APPS = [
  {
    name: 'CENTRE DOCUMENTAIRE',
    description: 'pageHome.appAFF',
    link: AFF_URL,
    logo: '/assets/images/favicons/AFF.jpg'
  }
];

export const ACCESS_APPS = [
  'DATABILAN',
  'DATAFORM'
];

export const CUSTOM_HEADER = {
  DATABILAN: {
    logo: 'logos/databilan.png',
    bg: 'bg/databilanHeader.png',
    color: '#0297D8'
  },
  DATAFORM: {
    logo: 'logos/dataform.png',
    color: '#005479'
  },
  DATATECNEA: {
    logo: 'logos/datatecnea.png',
    color: '#61727a'
  }
};

export const VALIDATED_TYPES = [
  {
    key: 'validated'
  },
  {
    key: 'unvalidated'
  }
];

export const ACTIVATED_TYPES = [
  {
    key: 'activated'
  },
  {
    key: 'disabled'
  }
];

export const QUESTION_TYPE = {
  mcq: 'BASIC',
  dropDown: 'SELECT',
  yesNo: 'YES_NO',
  trueFalse: 'TRUE_FALSE',
  rating: 'SCORE',
  shortInput: 'SHORT_INPUT',
  longInput: 'LONG_INPUT',
  imagePicker: 'IMAGE',
  dateInput: 'DATE',
  emailInput: 'EMAIL',
  phoneInput: 'PHONE'
};

export const STRUCTURE_TYPE = {
  COMPANY: 'COMPANY',
  PARENT_COMPANY: 'PARENT_COMPANY',
  INSTITUTION_MODEL: 'INSTITUTION_MODEL',
  INSTITUTION_GROUP: 'INSTITUTION_GROUP'
};

export const STRUCTURE_LEVEL_ICON = {
  COMPANY: faStoreAlt,
  PARENT_COMPANY: faStoreAlt,
  INSTITUTION_MODEL: faBuilding,
  INSTITUTION_GROUP: faSitemap
};

export const DOCUMENT_VALIDATION_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID'
};

export const APPLICATION_ACCESS = {
  DATABILAN_DISTRIBUTOR: 'DATABILAN_DISTRIBUTOR',
  DATABILAN_OPERATOR: 'DATABILAN_OPERATOR',
  DATAFLUIDES: 'DATAFLUIDES',
  DATAFORM: 'DATAFORM',
  DATAC2E: 'DATAC2E'
};

export const CATEGORY_FILTER_KEY = 'filterByCategory';
export const NEW_TAB_FROM_CATEGORY_LIST = 'newTabFromCategoryList';
